import { AnswerTag } from '@pla324/teuteuf-answer-tag';
import { InputForm } from '@pla324/teuteuf-input-form';
import { getCompassDirection, getDistance } from 'geolib';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { AdnginEndMobile0 } from '../../components/AdnginEndMobile0';
import { FlagGrid } from '../../components/FlagGrid';
import { GuessList } from '../../components/GuessList';
import { NextRoundLink } from '../../components/NextRoundLink';
import { ShareButton } from '../../components/ShareButton';
import {
  MAX_ATTEMPTS,
  TILE_COUNT,
  TILES_REVEALED_AT_START,
} from '../../constants';
import { useConfettiThrower } from '../../hooks/useConfettiThrower';
import { useDailySeed } from '../../hooks/useDailySeed';
import { useGuessHistory } from '../../hooks/useGuessHistory';
import { useTodaysCountry } from '../../providers/TodaysCountryProvider';
import { shuffleWithSeed } from '../../utils/shuffleWithSeed';
import { Attempts } from './components/Attempts';

const TILE_INDICES = Array.from({ length: TILE_COUNT }, (_, i) => i);

export function MainGameRoute() {
  const { todaysCountry, countryList, todaysCity } = useTodaysCountry();
  const [score, setScore] = useState('DNF');
  const [flippedArray, setFlippedArray] = useState(Array(6).fill(false));
  const dayString = useDailySeed();
  const [end, setEnd] = useState(false);
  const [guessHistory, addGuess] = useGuessHistory();
  const guesses = useMemo(
    () => guessHistory[dayString] || [],
    [guessHistory, dayString],
  );
  const [randomOrder, setRandomOrder] = useState(() =>
    shuffleWithSeed(TILE_INDICES, dayString).slice(guesses.length),
  );
  const countyInputRef = useRef < HTMLInputElement > undefined;
  const [currentGuess, setCurrentGuess] = useState('');

  const trueCountry = useMemo(() => todaysCountry.name, [todaysCountry]);

  const allCountryNames = useMemo(
    () => countryList.map((c) => c.name),
    [countryList],
  );

  const revealRandomTile = useCallback(() => {
    const [tile] = randomOrder;
    setRandomOrder(randomOrder.slice(1));
    setFlippedArray((currArray) => {
      const newFlipped = [...currArray];
      newFlipped[tile] = true;
      return newFlipped;
    });
    return tile;
  }, [setFlippedArray, randomOrder]);

  const getRemainingTiles = useCallback(() => {
    const remainingTiles = [];
    const usedTiles = guesses.map((guess) => guess.tile);
    for (const i of TILE_INDICES) {
      if (!usedTiles.includes(i)) {
        remainingTiles.push(i);
      }
    }
    return remainingTiles;
  }, [guesses]);

  const revealTiles = useCallback(() => {
    setFlippedArray((currFlipped) => {
      const newFlipped = [...currFlipped];

      for (const guess of guesses) {
        newFlipped[guess.tile] = true;
      }
      return newFlipped;
    });
  }, [setFlippedArray, guesses]);

  const throwConfetti = useConfettiThrower();

  useEffect(() => {
    if (end) return;
    revealTiles();
    getRemainingTiles();
    const lastGuess = guesses[guesses.length - 1];
    if (guesses.length >= MAX_ATTEMPTS || lastGuess?.distance === 0) {
      setEnd(true);
      setFlippedArray(Array(6).fill(true));
      if (guesses[guesses.length - 1].distance === 0) {
        toast(`🎉 ${trueCountry} 🎉`, { autoClose: 3000 });
        throwConfetti();
        setScore(guesses.length);
      } else {
        toast(`🤔 ${trueCountry} 🤔`, { autoClose: 3000 });
        setScore('DNF');
      }
    }
  }, [
    guesses,
    trueCountry,
    getRemainingTiles,
    revealTiles,
    throwConfetti,
    end,
  ]);

  // reveal the first tile when the game starts
  useEffect(() => {
    if (randomOrder.length < 6 || !TILES_REVEALED_AT_START) return;

    setFlippedArray((prev) => {
      const newFlippedArray = [...prev];
      for (let i = 0; i < TILES_REVEALED_AT_START; i++) {
        newFlippedArray[randomOrder[i]] = true;
      }
      return newFlippedArray;
    });

    setRandomOrder((randomOrder) => randomOrder.slice(1));
  }, [setFlippedArray, setRandomOrder, randomOrder]);

  const onGuess = useCallback(
    (e) => {
      e.preventDefault();
      if (!countryList) return;

      const guessedCountry = countryList.find(
        (c) => c.name.toUpperCase() === currentGuess.toUpperCase(),
      );

      if (guessedCountry == null) {
        toast.error('Unknown country', {
          autoClose: 3000,
        });
        setCurrentGuess('');
        return;
      }

      if (
        guesses.findIndex(
          (g) => g.name.toUpperCase() === currentGuess.toUpperCase(),
        ) !== -1
      ) {
        setCurrentGuess('');
        return toast.error(`You have already guessed ${currentGuess}`, {
          autoClose: 3000,
        });
      }
      const tileNum = revealRandomTile();
      const { ...guessGeo } = countryList.find(
        (c) => c.name.toUpperCase() === currentGuess.toUpperCase(),
      );
      const { ...answerGeo } = todaysCountry;
      addGuess({
        name: currentGuess.toUpperCase(),
        distance: getDistance(guessGeo, answerGeo),
        direction: getCompassDirection(guessGeo, answerGeo),
        tile: tileNum,
      });
      setCurrentGuess('');
    },
    [
      guesses,
      revealRandomTile,
      countryList,
      todaysCountry,
      addGuess,
      currentGuess,
    ],
  );

  return (
    <>
      <div className="flex flex-col w-full max-w-lg">
        <FlagGrid
          end={end}
          countryInfo={{ code: todaysCountry.code }}
          flippedArray={flippedArray}
        ></FlagGrid>
        {end && (
          <div className="flex w-full items-center justify-center">
            <AnswerTag
              answerState={
                guesses.length && guesses[guesses.length - 1].distance === 0
                  ? 'right'
                  : 'default'
              }
              text={
                <>
                  Flag:&nbsp;
                  <span className="font-semibold">
                    {trueCountry?.toUpperCase() ?? ''}
                  </span>
                </>
              }
              textSize="text-md"
              padding="py-1 px-2"
              margin="mt-3"
            />
          </div>
        )}
        {TILES_REVEALED_AT_START > 0 || guesses?.length > 0 ? (
          <br />
        ) : (
          <p className="my-2 text-sm">Make a guess to reveal the first tile</p>
        )}
        {!end && (
          <div className="text-start">
            <InputForm
              handleSubmit={onGuess}
              inputRef={countyInputRef}
              currentGuess={currentGuess}
              setCurrentGuess={setCurrentGuess}
              autosuggestValues={allCountryNames?.sort() ?? []}
              placeholderText={'Country...'}
              emojiButtonProps={{
                emoji: '🌍',
                label: 'GUESS',
              }}
            />
          </div>
        )}
        <Attempts score={score} attempts={guesses.length} max={MAX_ATTEMPTS} />
        <GuessList guesses={guesses} />
      </div>
      {end && (
        <>
          <NextRoundLink to="/bonus-round/1">
            Bonus Round - {todaysCity?.flag ? '1/5' : '1/4'} - Pick the country
            shape
          </NextRoundLink>

          <ShareButton />
        </>
      )}
      <AdnginEndMobile0 />
    </>
  );
}
